import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/elizabethrogers/websites/beth-gatsby-blog/src/templates/page.tsx";
export const pageProps = {
  title: "About Me",
  metaTitle: "About Me",
  keywords: ["about me", "Elizabeth Rogers"],
  css: {
    maxWidth: "1024px",
    margin: "auto"
  }
};
export const _frontmatter = {};
const layoutProps = {
  pageProps,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Hello there, my name is Elizabeth Rogers. I reside in the beautiful city of Austin, TX, alongside my loving husband and three adorable dogs - Jessie, Chloe, and Luna. With over 15 years of experience in website development, I take great pride in helping businesses provide the best user experience for their customers.`}</p>
    <p>{`My expertise lies in creating websites using HTML, CSS, JavaScript, and PHP. I firmly believe that a website is not only a digital platform but also an opportunity to make a lasting impression on your target audience. Therefore, I strive to build websites that are not only visually appealing but also functional and user-friendly.`}</p>
    <p>{`I have a passion for developing apps with Laravel and using Vue and React to write JavaScript and CSS. My love for exploring new technologies and techniques in website development has allowed me to stay ahead of the game and provide the best services to my clients.`}</p>
    <p>{`Thank you for taking the time to read about me. I would be honored to help you create a website that reflects your brand's vision and values. Please feel free to contact me if you have any questions or if you're interested in working together.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      