import React from "react";
import { MDXProvider } from "@mdx-js/react";

import { LayoutMain } from "../layouts/layout-main/layout-main";
import { pageContent } from "./page.module.scss";

export default function Page({
  children,
  pageProps,
}: {
  children: React.ReactNode;
  pageProps: {
    metaTitle?: string;
    title: string;
  };
}) {
  const {
    // className,
    metaTitle,
    title,
    // css = {
    //   maxWidth: "1024px",
    //   margin: "auto",
    // },
  } = pageProps;

  const shortcodes = {};

  return (
    <LayoutMain isBlogPost={false} title={title} metaTitle={metaTitle}>
      <div className="blog-post-container">
        <article className={pageContent}>
          <h1 className="page-title">{title}</h1>
          <MDXProvider components={shortcodes}>
            <div>{children}</div>
          </MDXProvider>
        </article>
      </div>
    </LayoutMain>
  );
}
